import { Fragment, useState, useEffect } from "react";
import { Disclosure, Dialog, Menu, Transition } from "@headlessui/react";
import {
    Bars3Icon,
    QrCodeIcon,
    XMarkIcon,
    GlobeAltIcon,
    LinkIcon,
    StarIcon,
    MegaphoneIcon,
    ChevronRightIcon,
    HomeIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/24/solid";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useLogout } from "../hooks/useLogout";

export default function Navigation({ children }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();

    const { user } = useAuth();
    const { logout } = useLogout();

    const navigation = [
        { name: "Dashboard", href: "/", icon: HomeIcon },
        {
            name: "Landing Pages",
            href: "/landingpage",
            icon: GlobeAltIcon,
            children: [
                { name: "Landing Pages", href: "/landingpage/pages" }, // Updated unique path
                { name: "Customers", href: "/landingpage/customers" },
            ],
        },
        {
            name: "Ambassador Programs",
            href: "/ambassador",
            icon: MegaphoneIcon,
            children: [
                { name: "Programs", href: "/ambassador/programs" }, // Updated unique path
                { name: "Products", href: "/ambassador/products" },
                { name: "Ambassadors", href: "/ambassador/ambassadors" },
            ],
        },
        // {
        //     name: "Reviews",
        //     href: "/reviews",
        //     icon: StarIcon,
        //     children: [
        //         { name: "Surveys", href: "/reviews/surveys" }, // Updated unique path
        //         { name: "Products", href: "/reviews/products" },
        //         { name: "Giveaways", href: "/reviews/giveaways" },
        //         { name: "Reviews", href: "/reviews/reviews" },
        //     ],
        // },
        { name: "QR Codes", href: "/qrcodes", icon: QrCodeIcon },
        { name: "Links", href: "/links", icon: LinkIcon },
    ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    let active =
        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-gray-800 text-white";
    let inactive =
        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800";

    const handleLogout = () => {
        logout();
        window.location.replace("/login");
    };

    // Ensure only the exact path is active
    const isActive = (match, location) => {
        return match && match.isExact;
    };

    const userNavigation = [
        { name: "Account", onClick: () => {}, href: "/account" },
        // { name: "Marketplaces", onClick: () => {}, href: "/marketplaces" },
        { name: "Billing", onClick: () => {}, href: "/billing" },
        { name: "Sign out", onClick: handleLogout },
    ];

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50 lg:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src="/img/logomark.png"
                                                alt="Gotomarket.tools"
                                            />
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            {navigation.map((item) => (
                                                <Disclosure
                                                    as="div"
                                                    key={item.name}
                                                    defaultOpen={location.pathname.startsWith(
                                                        item.href
                                                    )}
                                                >
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button
                                                                className={classNames(
                                                                    "flex items-center justify-between w-full p-2 text-sm font-semibold",
                                                                    open
                                                                        ? "text-white bg-gray-800"
                                                                        : "text-gray-400 hover:text-white hover:bg-gray-800"
                                                                )}
                                                            >
                                                                <span className="flex items-center gap-x-3">
                                                                    <item.icon
                                                                        className="h-6 w-6"
                                                                        aria-hidden="true"
                                                                    />
                                                                    {item.name}
                                                                </span>
                                                                {item.children && (
                                                                    <ChevronDownIcon
                                                                        className={classNames(
                                                                            "h-5 w-5 transform",
                                                                            open
                                                                                ? "rotate-180"
                                                                                : ""
                                                                        )}
                                                                    />
                                                                )}
                                                            </Disclosure.Button>
                                                            {item.children && (
                                                                <Disclosure.Panel
                                                                    as="ul"
                                                                    className="pl-9"
                                                                >
                                                                    {item.children.map(
                                                                        (
                                                                            subItem
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    subItem.name
                                                                                }
                                                                            >
                                                                                <NavLink
                                                                                    to={
                                                                                        subItem.href
                                                                                    }
                                                                                    className={({
                                                                                        isActive,
                                                                                    }) =>
                                                                                        (isActive
                                                                                            ? "text-white"
                                                                                            : "text-gray-400 hover:text-white") +
                                                                                        " block px-2 py-1 text-sm"
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        subItem.name
                                                                                    }
                                                                                </NavLink>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </Disclosure.Panel>
                                                            )}
                                                        </>
                                                    )}
                                                </Disclosure>
                                            ))}
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-4 pb-4">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                className="h-8 w-auto mt-4"
                                src="/img/logomark.png"
                                alt="gotomarket.tools"
                            />
                        </div>

                        <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col gap-y-2">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        {!item.children ? (
                                            <NavLink
                                                to={item.href} // Changed from subItem.href to item.href
                                                className={({ isActive }) =>
                                                    isActive ? active : inactive
                                                }
                                            >
                                                <item.icon
                                                    className="h-6 w-6 shrink-0"
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </NavLink>
                                        ) : (
                                            <Disclosure
                                                as="div"
                                                defaultOpen={location.pathname.startsWith(
                                                    item.href
                                                )}
                                            >
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button
                                                            className={classNames(
                                                                open
                                                                    ? active
                                                                    : inactive,
                                                                "flex items-center w-full justify-between"
                                                            )}
                                                        >
                                                            <span className="flex gap-x-3">
                                                                <item.icon
                                                                    className="h-6 w-6 shrink-0"
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </span>
                                                            <ChevronRightIcon
                                                                className={classNames(
                                                                    open
                                                                        ? "rotate-90"
                                                                        : "",
                                                                    "h-5 w-5"
                                                                )}
                                                            />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel
                                                            as="ul"
                                                            className="mt-1 pl-9"
                                                        >
                                                            {item.children.map(
                                                                (subItem) => (
                                                                    <li
                                                                        key={
                                                                            subItem.name
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to={
                                                                                subItem.href
                                                                            }
                                                                            className={({
                                                                                isActive,
                                                                            }) =>
                                                                                isActive
                                                                                    ? active
                                                                                    : inactive
                                                                            }
                                                                        >
                                                                            {
                                                                                subItem.name
                                                                            }
                                                                        </NavLink>
                                                                    </li>
                                                                )
                                                            )}
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="lg:pl-72">
                    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                        <button
                            type="button"
                            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Separator */}
                        <div
                            className="h-6 w-px bg-gray-900/10 lg:hidden"
                            aria-hidden="true"
                        />

                        <div className="flex flex-1 gap-x-4 self-stretch justify-end lg:gap-x-6">
                            <div className="flex items-center gap-x-4 lg:gap-x-6">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">
                                            Open user menu
                                        </span>
                                        <span className="hidden lg:flex lg:items-center">
                                            <span
                                                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                                aria-hidden="true"
                                            >
                                                {user &&
                                                    `${user.firstName} ${user.lastName}`}
                                            </span>
                                            <ChevronDownIcon
                                                className="ml-2 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <NavLink
                                                            to={item.href}
                                                            onClick={
                                                                item.onClick
                                                            }
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-50"
                                                                    : "",
                                                                "block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900"
                                                            )}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <main className="py-10 px-4 sm:px-6 lg:px-8">
                        {children}
                    </main>
                </div>
            </div>
        </>
    );
}
